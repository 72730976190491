import React from "react"
import { graphql } from "gatsby"
import { StateProvider } from "state/state"
import { reducer, initialState } from "state/reducer"
import Layout from "components/Layout"
import "../styles/contactus.scss"
import ExternalLink from "../components/Modal/ExternalLink"
import { Link } from "gatsby"
import { BLOCKS, MARKS, INLINES } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { Container, Row, Col } from "react-bootstrap"
import SEO from "components/Seo"


const ContactUs = ({ data }) => {
  data = data.contentfulHcpContactUs;
  const options = {
    renderMark: {
      [MARKS.CODE]: text => (
        <span style={{ whiteSpace: "nowrap" }}>{text}</span>
      ),
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) =>
      node.content[0].value !== "" ? children : null,
      [BLOCKS.HEADING_1]: (node, children) =>
      node.content[0].value !== "" ? <h1>{children}</h1> : null,
      [BLOCKS.HEADING_2]: (node, children) =>
        node.content[0].value !== "" ? <h2>{children}</h2> : null,
      [BLOCKS.UL_LIST]: (node, children) =>
        node.content[0].value !== "" ? <ul>{children}</ul> : null,
      [BLOCKS.LIST_ITEM]: (node, children) =>
      node.content[0].value !== "" ? <li>{children}</li> : null,
      [INLINES.HYPERLINK]: node => {
        if(node.data.uri === '/getstarted/' || node.data.uri === '/signup/'){
          return <Link to={node.data.uri}>{node.content[0].value}</Link>
        } else if (node.data.uri === 'https://www.palforziapro.com/static/enrollment.pdf') {
          return <a href={"/enrollment.pdf"} target={"_blank"}>{node.content[0].value}</a>
        } else {
          return (
            <ExternalLink url={node.data.uri} target={"_blank"}>
              {node.content[0].value}
            </ExternalLink>
          )
        }
      },
    },
    renderText: text => {
      return text;
    },
  }
  console.log('data ', data)
  return (
    <StateProvider initialState={initialState} reducer={reducer}>
  <Layout>
    <SEO title={data.title} description={data.description}></SEO>
    <section className="contactus">
      <Container>
        <Row>
          <Col lg={6}>
            {documentToReactComponents(data.headline.json,
                options
            )}
            <Col lg={8}>
              <img alt={data.alt} src={data.image.localFile.url} width="100%"></img>
            </Col>
          </Col>
          <Col lg={6}>
            {documentToReactComponents(data.content.json,
                options
              )}
          </Col>
        </Row>
      </Container>
    </section>
  </Layout>
    </StateProvider>
  )
}

export default ContactUs

export const query = graphql`
      query {
        contentfulHcpContactUs {
          title
          description
          headline {
            json
          }
          image {
            localFile {
              url
            }
          }
          alt
          content {
            json
          }
        }
  }
`
